import { CircleStatus, Notification } from '@digital-at-vallourec/steel-design-system-react';
import { $lightBlue } from '@digital-at-vallourec/steel-design-system-react/dist/styles/colors';
import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { TypeOf } from 'zod';

import { useAppSelector } from '../../../hooks';
import { AxiosSetModelManagementDetails, UpdateSetModelApi } from '../../../interfaces/set-model';
import { updateSetModel } from '../../../services/api/set-model-api';
import { selectUser } from '../../../services/store/userSlice';
import { VegWebsiteStatus } from '../../../utils';
import { formSchema } from '../../../utils/constants/set-model';
import {
  DesignationProduct,
  getApplicabilitiesFields,
  getDesignationProduct,
} from '../../../utils/functions';
import { redirectTo } from '../../../utils/functions/helpers';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { CtaLoadingButton, MenuOptionsProps, VamMoreOptions, VamPageTitle } from '../../shared';
import { Applicabilities } from '../Applicabilities';
import { CaseModel } from '../CaseModel';
import { GaugeModel } from '../GaugeModel';
import SetConnection from '../SetConnection';
import { isRequiredModified } from '../utils';

// infer schema to generate typescript type
type FormSchemaType = TypeOf<typeof formSchema>;

export const UpdateSetModel = () => {
  const params = useParams();
  const [{ data: setModelDetails }, { data: checkLinked }] = useLoaderData() as [
    AxiosSetModelManagementDetails,
    AxiosResponse<boolean, any>
  ];

  const { t: tGauge } = useTranslation('gauge');
  const { t } = useTranslation('gauge', { keyPrefix: 'setModel' });
  const { t: translateCommon } = useTranslation('common');
  const [loading, setLoading] = React.useState(false);
  const [showNotificationSetModelAlreadyExist, setShowNotificationSetModelAlreadyExist] =
    React.useState(false);
  const [websiteStatus, setWebsiteStatus] = React.useState<VegWebsiteStatus>(
    setModelDetails?.set_model?.website_status
  );
  const user = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: FormSchemaType = {
    connection: setModelDetails.set_model?.connection || '',
    od_inch: setModelDetails.set_model?.od_inch || ('' as any),
    end: setModelDetails.set_model?.end || '',
    min_weight: setModelDetails.set_model?.min_weight || ('' as any),
    max_weight: setModelDetails.set_model?.max_weight || ('' as any),
    application: setModelDetails.set_model?.application || '',
    option: setModelDetails.set_model?.option || '',
    gauge_models: setModelDetails.gauges_models?.map((gaugeModel) => ({
      gauge_model_id: gaugeModel.id,
      required: !!gaugeModel.is_gauge_required,
    })) as any,
    applications: setModelDetails.applicabilities as any,
    case_id: setModelDetails.case?.case_id,
    applicabilities_connection: setModelDetails.set_model?.connection || '',
    applicabilities_od: setModelDetails.set_model?.od_inch || ('' as any),
    total_weight: setModelDetails.set_model?.total_weight || ('' as any),
  };

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });
  const { handleSubmit, formState, getValues } = methods;
  const [designation, setDesignation] = React.useState<string>(null);
  const [urlSetModel, setUrlSetModel] = React.useState<string>('');
  const navigate = useNavigate();
  const { set_model, case: setModelCase, applicabilities, gauges_models } = setModelDetails;

  /* istanbul ignore next */
  const optionsMenu: MenuOptionsProps[] = [
    {
      label: tGauge('websiteStatus.active'),
      name: VegWebsiteStatus.Active,
      handleItemClick: () => {
        handleModelChangeStatus(VegWebsiteStatus.Active);
      },
    },
    {
      label: tGauge('websiteStatus.draft'),
      name: VegWebsiteStatus.Draft,
      handleItemClick: () => {
        handleModelChangeStatus(VegWebsiteStatus.Draft);
      },
      disabled: checkLinked,
    },
    {
      label: tGauge('websiteStatus.waiting'),
      name: VegWebsiteStatus.Waiting,
      handleItemClick: () => {
        handleModelChangeStatus(VegWebsiteStatus.Waiting);
      },
      disabled: checkLinked,
    },
  ].filter((item) => item.name !== websiteStatus);

  const [websiteStatusOptions, setWebsiteStatusOptions] =
    React.useState<MenuOptionsProps[]>(optionsMenu);

  /* istanbul ignore next */
  const handleModelChangeStatus = (target_status: VegWebsiteStatus) => {
    setWebsiteStatus(target_status);
    const options = optionsMenu.filter((item) => item.name !== target_status);
    setWebsiteStatusOptions(options);
  };

  /* istanbul ignore next */
  const handleUpdateSetModel: SubmitHandler<FormSchemaType> = (data) => {
    setLoading(true);
    const {
      connection,
      od_inch,
      min_weight,
      max_weight,
      end,
      application,
      option,
      case_id,
      applications,
      gauge_models,
      total_weight,
    } = data;
    const new_set_model: UpdateSetModelApi = {
      website_status: websiteStatus,
      connection,
      od_inch,
      min_weight,
      max_weight,
      end,
      application,
      option,
      modification_date: new Date(),
      modified_by: `${user.lastName} ${user.firstName}`,
      case_id,
      applicabilities: getApplicabilitiesFields(applications),
      gauges_models: gauge_models,
      total_weight: parseFloat(total_weight),
    };
    updateSetModel(params['setModelId'], new_set_model)
      .then(() => {
        setLoading(false);
        enqueueSnackbar(translateCommon('notification.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        redirectTo('../set-models-management', navigate);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.status === 409) {
          const productDesignation: DesignationProduct = {
            connection: new_set_model.connection,
            od_inch: new_set_model.od_inch,
            end: new_set_model.end,
            min_weight: new_set_model.min_weight,
            max_weight: new_set_model.max_weight,
            application: new_set_model.application,
            option: new_set_model.option,
          };
          setDesignation(getDesignationProduct(productDesignation));
          setUrlSetModel(`../set-models-management/${error.response.data.set_model_already_exist}`);
          setShowNotificationSetModelAlreadyExist(true);
          enqueueSnackbar(t('setModelAlreadyExists'), {
            preventDuplicate: true,
            variant: 'error',
          });
        } else {
          enqueueSnackbar(translateCommon('notification.error'), {
            preventDuplicate: true,
            variant: 'error',
          });
        }
        console.error(error);
      });
  };

  /* istanbul ignore next */
  const handleNotificationUpdateButtonClick = () => {
    redirectTo(urlSetModel, navigate);
    setShowNotificationSetModelAlreadyExist(false);
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('tag')}
              title={t('title', { context: 'update' })}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Set Models Management',
                  path: '/admin/vam-easy-gauge/set-model/set-models-management',
                },
                {
                  breadcrumb: 'Update a set model',
                  path: '#',
                },
              ]}
              detailsLabelBox={[
                {
                  label: params['setModelId'],
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            {!showNotificationSetModelAlreadyExist ? (
              <>
                <CircleStatus
                  variant={websiteStatus}
                  label={translateCommon(`websiteStatus.${websiteStatus}`)}
                ></CircleStatus>
                <VamMoreOptions
                  options={websiteStatusOptions}
                  data-testid="vam-menu-detail-actions"
                />
                <CtaLoadingButton
                  loading={loading}
                  buttonText={t('updateModel')}
                  type="submit"
                  variant="cta"
                  data-testid="submit-update-set-model-btn"
                  startIcon={<Check />}
                  onClick={handleSubmit(handleUpdateSetModel)}
                  disabled={
                    formState.isValid && formState.dirtyFields.total_weight // if total_weight has been manually changed
                      ? false
                      : loading ||
                        (set_model?.website_status !== VegWebsiteStatus.Draft &&
                          getValues('applications')?.length === applicabilities.length &&
                          getValues('gauge_models')?.length === gauges_models.length &&
                          !isRequiredModified(gauges_models, getValues('gauge_models')))
                  }
                />
              </>
            ) : (
              <>
                <Notification
                  title={t('setModelAlreadyExists')}
                  description={t('doYouModifySetModel') + ' ' + designation}
                  closeText={t('stayOnThisVersion')}
                  buttonText={t('modifySetModel')}
                  severity="info"
                  handleClose={
                    /* istanbul ignore next */ () => setShowNotificationSetModelAlreadyExist(false)
                  }
                  handleButtonClick={
                    /* istanbul ignore next */ () => handleNotificationUpdateButtonClick()
                  }
                />
              </>
            )}
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid item xs={12}>
            <SetConnection setModel={set_model} />
          </Grid>
          <Grid item xs={6}>
            <Applicabilities
              websiteStatus={set_model?.website_status}
              applicabilities={applicabilities}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              rowSpacing={4}
              style={{
                backgroundColor: 'transparent',
              }}
            >
              <Grid item xs={12}>
                <CaseModel websiteStatus={set_model?.website_status} case={setModelCase} />
              </Grid>
              <Grid item xs={12} data-testid="gauge-model-section">
                <GaugeModel websiteStatus={set_model?.website_status} gaugeModels={gauges_models} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
};
